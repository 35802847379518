import { StateCreator, StoreApi } from 'zustand';
import { AppStore } from '../../../store/types';
import { ProjectSlice } from './types';
import {
  clearProjectMessages,
  createProject,
  deleteProject,
  getProject,
  getProjectCriteria,
  sendProjectMessage,
  updateProject,
  updateProjectCriteria,
} from '../../../api/api';
import { DraftProject, Message, Project, ProjectCriteriaValues } from 'api-types';

const initialState: Partial<ProjectSlice> = {
  project: undefined,
  projectCriteria: undefined,
};

export const createProjectSlice = (
  set: StoreApi<ProjectSlice>['setState'],
  get: StoreApi<AppStore>['getState']
): ProjectSlice => ({
  ...initialState,
  activeProjectId: undefined,
  resetProject: () => {
    const { resetOutreachSlice, resetCandidateSlice } = get();
    resetOutreachSlice();
    resetCandidateSlice();
    set({
      ...initialState,
    });
  },
  setActiveProjectId: (id: string | undefined) => {
    set({ activeProjectId: id, project: undefined });
  },
  upsertProject: async (draftProject: DraftProject, projectId) => {
    let createdProject: Project | undefined;
    if (projectId) {
      const { project } = await updateProject({ projectId, project: draftProject });
      createdProject = project;
    } else {
      const { project } = await createProject({ project: draftProject });
      createdProject = project;
    }
    set({
      project: createdProject,
      activeProjectId: createdProject?.id,
    });
    return createdProject;
  },
  fetchProject: async () => {
    const { activeProjectId, resetProject } = get();
    if (!activeProjectId) {
      return;
    }
    resetProject();
    const { project } = await getProject({ projectId: activeProjectId });
    if (activeProjectId === project?.id) {
      set({ project });
    }
    return project;
  },
  appendNewMessage: (message: Message) => {
    const { project } = get();
    const projectMessages = [...(project?.messages || []), message];
    set({ project: { ...project, messages: projectMessages } });
  },
  sendProjectMessage: (userMessage: string, callbacks) => {
    const { activeProjectId } = get();
    if (!activeProjectId) {
      return;
    }
    return sendProjectMessage(
      {
        projectId: activeProjectId,
        message: userMessage,
      },
      callbacks
    );
  },
  clearProjectMessages: async () => {
    const { activeProjectId } = get();
    if (!activeProjectId) {
      return;
    }
    await clearProjectMessages({ projectId: activeProjectId });
    const { activeProjectId: lastActiveProjectId, project } = get();
    if (activeProjectId === lastActiveProjectId) {
      set({ project: { ...project, messages: [] } });
    }
  },
  deleteProject: async () => {
    const { activeProjectId, getBootstrapInfo } = get();
    if (!activeProjectId) {
      return;
    }
    await deleteProject({ projectId: activeProjectId });
    await getBootstrapInfo();
    set({ ...initialState });
  },
  fetchProjectCriteriaForm: async () => {
    const { activeProjectId } = get();
    if (!activeProjectId) {
      return;
    }
    const { criteriaForm, projectId } = await getProjectCriteria({ projectId: activeProjectId });
    if (projectId === activeProjectId) {
      set({ projectCriteria: criteriaForm });
    }
    return criteriaForm;
  },
  updateProjectCriteria: async (criteriaValues: ProjectCriteriaValues) => {
    const { activeProjectId, projectCriteria } = get();
    const { criteria } = await updateProjectCriteria({
      projectId: activeProjectId,
      criteria: criteriaValues,
    });
    set({
      projectCriteria: {
        ...projectCriteria,
        values: criteria,
      },
    });
  },
});
