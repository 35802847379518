import { Modal, ModalDialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/joy';

export const DeleteInterviewDialog = ({
  open,
  onClose,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>Delete Interview</DialogTitle>
        <DialogContent>Are you sure you want to delete this interview?</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onDelete}>
            Delete
          </Button>
          <Button variant="plain" color="neutral" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
