import React, { useState, Suspense } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from 'shadcn/components/ui/card';
import { Button } from 'shadcn/components/ui/button';
import { generateRoomId } from './room-setup/lib/utils';
import { useNavigate } from 'react-router-dom';
import { ROOM_PATH } from '../constants';

const LiveInterviewHome: React.FC = () => {
  const navigate = useNavigate();
  const startMeeting = () => {
    const roomName = generateRoomId();
    navigate(`${ROOM_PATH}/${roomName}`);
  };

  return (
    <div className="flex-grow flex items-center justify-center px-4 bg-white">
      <Card className="w-full max-w-2xl shadow-lg bg-transparent">
        <CardHeader className="pb-4">
          <CardTitle className="text-2xl md:text-3xl font-bold text-center text-black">
            Welcome to Your Interview
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-8">
          <section>
            <h2 className="text-lg md:text-xl font-semibold mb-3 text-black">Guidelines</h2>
            <ul className="space-y-2 text-gray-700">
              <li className="flex items-start">
                <span className="mr-2">•</span>
                Find a quiet, well-lit space for your interview
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                Ensure your internet connection is stable
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                Have a copy of your resume nearby
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                Be prepared to discuss your experiences and skills
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-lg md:text-xl font-semibold mb-3 text-black">Instructions</h2>
            <ol className="space-y-2 text-gray-700 list-decimal list-inside">
              <li>Click the "Start Interview" button below when you're ready</li>
              <li>You'll be asked a series of questions - take your time to answer thoughtfully</li>
              <li>If you need to pause, use the controls provided during the interview</li>
              <li>After completing all questions, you'll be able to review your responses</li>
            </ol>
          </section>

          <div className="flex justify-center pt-4">
            <Button
              onClick={startMeeting}
              className="bg-black hover:bg-gray-800 text-white px-8 py-2.5 rounded-full transition-all duration-300 ease-in-out transform hover:scale-[1.02] active:scale-[0.98]"
            >
              Start Interview
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LiveInterviewHome;
