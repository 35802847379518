import { useState } from 'react';
import { Schema } from 'yup';

export function useListErrors(itemSchema: Schema, listSchema: Schema) {
  const [itemErrors, setItemErrors] = useState<(string | undefined)[]>([]);
  const [listErrors, setListErrors] = useState<string | undefined>();

  const validateItem = (index: number, value: string) => {
    try {
      itemSchema.validateSync(value);
      setItemErrors(prevErrors => {
        const newErrors = [...prevErrors];
        newErrors[index] = undefined;
        return newErrors;
      });
    } catch (error) {
      if (error instanceof Error) {
        setItemErrors(prevErrors => {
          const newErrors = [...prevErrors];
          newErrors[index] = (error as any).message;
          return newErrors;
        });
      }
      return false;
    }
    return true;
  };

  const validateList = (list: string[]) => {
    const areItemsValid = list.reduce((acc, item, index) => {
      const isItemValid = validateItem(index, item);
      return acc && isItemValid;
    }, true);

    let isListValid = false;

    try {
      listSchema.validateSync(list);
      setItemErrors([]);
      setListErrors(undefined);
      isListValid = true;
    } catch (error) {
      console.log(error);
      if (error instanceof Error && areItemsValid) {
        setListErrors((error as any).message);
      } else {
        setListErrors(undefined);
      }
    }

    return areItemsValid && isListValid;
  };

  return { itemErrors, validateItem, validateList, listErrors };
}
