import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/joy';
import { Card } from 'components/styled/Card';
import { useAppStore } from 'store/appStore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DeleteInterviewDialog } from '../edit-interview/components/DeleteInterviewDialog';
import styles from './interview-home.module.css';
import { CREATE_PATH, EDIT_PATH, INTERVIEW_PATH, VIEW_PATH } from '../app/constants';
import { INTERVIEW_SESSION_PATH } from 'pages/interview-session/constants';
import { InterviewConfig } from 'api-types';

export function InteviewHomeScreen() {
  const allInterviews = useAppStore(state => state.interviewConfigs);
  const fetchAllInterviews = useAppStore(state => state.fetchAllInterviews);
  const deleteInterviewConfig = useAppStore(state => state.deleteInterviewConfig);
  const [deleteInterviewId, setDeleteInterviewId] = useState<string | null>(null);
  const [copiedInterviewId, setCopiedInterviewId] = useState(false);
  const interviewLibraryConfigs = useAppStore(state => state.interviewLibraryConfigs);
  const fetchInterviewLibraryData = useAppStore(state => state.fetchInterviewLibraryData);
  useEffect(() => {
    fetchAllInterviews();
    fetchInterviewLibraryData();
  }, []);

  const handleDeleteInterview = (id: string) => {
    setDeleteInterviewId(id);
  };

  const handleCopyInterview = (id: string) => {
    const link = `${window.location.origin}${INTERVIEW_SESSION_PATH}/${id}`;
    try {
      navigator.clipboard.writeText(link);
      setCopiedInterviewId(true);
      setTimeout(() => {
        setCopiedInterviewId(false);
      }, 2000);
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
    }
  };

  const handleUseInterviewTemplate = (interviewConfig: InterviewConfig) => {
    navigate(`${INTERVIEW_PATH}${CREATE_PATH}`, {
      state: {
        defaultInterviewConfig: interviewConfig,
      },
    });
  };

  const navigate = useNavigate();
  return (
    <div>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography level="title-lg">Your Tests</Typography>
        <Button onClick={() => navigate(`${INTERVIEW_PATH}${CREATE_PATH}`)}>Create Test</Button>
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Box display={'flex'} gap={2} flexWrap={'wrap'}>
        {allInterviews?.map(interview => (
          <Card key={interview.id} size="sm" className={styles.card}>
            <CardContent>
              <Typography level="title-md">{interview.metaInfo?.name}</Typography>
              <Typography level="body-sm">{interview.metaInfo?.description}</Typography>
            </CardContent>
            <CardActions sx={{ gap: '10px' }}>
              <Tooltip title="Delete the Test" size="sm">
                <IconButton
                  color="neutral"
                  sx={{ mr: 'auto' }}
                  size="sm"
                  onClick={() => handleDeleteInterview(interview.id!)}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  copiedInterviewId
                    ? 'Copied interview link'
                    : 'Copy the test link to share with candidates'
                }
                size="sm"
              >
                <IconButton
                  color="neutral"
                  sx={{ ml: 'auto' }}
                  size="sm"
                  onClick={() => handleCopyInterview(interview.id!)}
                >
                  <ContentCopyOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Button
                variant="plain"
                color="neutral"
                size="sm"
                onClick={() => navigate(`${INTERVIEW_PATH}${EDIT_PATH}/${interview.id}`)}
              >
                Edit
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <DeleteInterviewDialog
        open={!!deleteInterviewId}
        onClose={() => setDeleteInterviewId(null)}
        onDelete={() => {
          if (deleteInterviewId) {
            deleteInterviewConfig(deleteInterviewId);
            setDeleteInterviewId(null);
          }
        }}
      />
      <Box marginTop={5}>
        <Box>
          <Typography level="title-lg">Library Templates</Typography>
        </Box>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Box display={'flex'} gap={2} flexWrap={'wrap'}>
          {interviewLibraryConfigs?.map(interview => (
            <Card key={interview.id} size="sm" className={styles.card}>
              <CardContent>
                <Typography level="title-md">{interview.metaInfo?.name}</Typography>
                <Typography level="body-sm">{interview.metaInfo?.description}</Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  variant="plain"
                  color="neutral"
                  size="sm"
                  onClick={() => handleUseInterviewTemplate(interview)}
                >
                  Use Template
                </Button>
                <Button
                  variant="plain"
                  color="neutral"
                  size="sm"
                  onClick={() => navigate(`${INTERVIEW_PATH}${VIEW_PATH}/${interview.id}`)}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      </Box>
    </div>
  );
}
