import { Box, Button, Step, StepIndicator, Stepper } from '@mui/joy';
import { useEffect, useState } from 'react';
import styles from './InterviewEditor.module.css';
import { InterviewMetaInfoEdit, metaInfoSchema } from './components/InterviewMetaInfoEdit';
import {
  InterviewScreeningCriteriaEdit,
  screeningCriteriaSchema,
} from './components/InterviewScreeningCriteriaEdit';
import { InterviewQuestionsEdit } from './components/InterviewQuestionsEdit';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppStore } from 'store/appStore';
import { useSnackbar } from 'components/Snackbar/SnackbarContext';
import { SpinnerLoader } from 'components/Loader/SpinnerLoader';
import { InterviewConfig } from 'api-types';
import { object, string, number, date, InferType, array } from 'yup';
import { FormikProps, useFormik } from 'formik';

export const interviewSchema = object({
  metaInfo: metaInfoSchema,
  screeningCriteria: screeningCriteriaSchema,
});

const STAGES = [
  {
    title: 'Interview Details',
    component: InterviewMetaInfoEdit,
  },
  {
    title: 'Screening Criteria',
    component: InterviewScreeningCriteriaEdit,
  },
  {
    title: 'Questions',
    component: InterviewQuestionsEdit,
  },
];

export function InterviewEditor() {
  const [currentStageIndex, setCurrentStageIndex] = useState(0);
  const navigate = useNavigate();
  const { interviewId } = useParams();
  const fetchInterviewConfig = useAppStore(state => state.fetchInterviewConfig);
  const setActiveInterviewId = useAppStore(state => state.setActiveInterviewId);
  const resetInterviewConfig = useAppStore(state => state.resetInterviewConfig);
  const upsertInterviewConfig = useAppStore(state => state.upsertInterviewConfig);
  const updateInterviewConfig = useAppStore(state => state.updateInterviewConfig);
  let interviewConfig = useAppStore(state => state.interviewConfig);
  const { state } = useLocation();
  const defaultInterviewConfig = state?.defaultInterviewConfig;
  if (!interviewId && defaultInterviewConfig) {
    interviewConfig = interviewConfig || defaultInterviewConfig;
  }

  const [updating, setUpdating] = useState(false);
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleGoToStage = (index: number) => {
    setCurrentStageIndex(index);
  };

  const fetchInterviewAsync = async () => {
    try {
      setLoading(true);
      const interviewConfig = await fetchInterviewConfig();
    } catch (error) {
      showErrorSnackbar('Failed to fetch interview');
    } finally {
      setLoading(false);
    }
  };

  const handleUpsertInterviewConfig = async () => {
    if (!interviewConfig) {
      return;
    }
    try {
      setUpdating(true);
      await upsertInterviewConfig(interviewConfig);
      showSuccessSnackbar('Interview updated successfully');
    } catch (error) {
      showErrorSnackbar('Failed to update interview');
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (interviewId) {
      setActiveInterviewId(interviewId);
      fetchInterviewAsync();
    }
  }, [interviewId]);

  const handleChange = (interviewConfig: InterviewConfig) => {
    updateInterviewConfig(interviewConfig);
  };

  const getCurrentStage = () => {
    const COMPONENT = STAGES[currentStageIndex].component;
    return (
      <COMPONENT
        interviewConfig={interviewConfig}
        handleChange={handleChange}
        handleBack={handleBack}
        handleNext={handleNext}
        submitting={updating}
      />
    );
  };

  useEffect(() => {
    return () => {
      resetInterviewConfig();
    };
  }, []);

  const handleNext = () => {
    if (currentStageIndex < STAGES.length - 1) {
      handleGoToStage(currentStageIndex + 1);
    } else {
      handleSave();
    }
  };

  const handleBack = () => {
    if (currentStageIndex > 0) {
      handleGoToStage(currentStageIndex - 1);
    }
  };

  const handleSave = async () => {
    await handleUpsertInterviewConfig();
    navigate('/');
  };

  return (
    <>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <>
          <Stepper size="sm" sx={{ flexGrow: 1 }}>
            {STAGES.map((stage, index) => (
              <Step
                className={styles.step}
                onClick={() => handleGoToStage(index)}
                indicator={
                  <StepIndicator
                    variant={currentStageIndex === index ? 'solid' : 'outlined'}
                    color={currentStageIndex === index ? 'primary' : 'neutral'}
                  >
                    {index + 1}
                  </StepIndicator>
                }
                orientation="vertical"
              >
                {stage.title}
              </Step>
            ))}
          </Stepper>

          <Box sx={{ padding: '40px', display: 'flex', flexDirection: 'column' }}>
            {getCurrentStage()}
          </Box>
        </>
      )}
    </>
  );
}
