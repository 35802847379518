import { Box, Button, FormControl, FormHelperText, IconButton, Typography } from '@mui/joy';
import { Textarea } from 'components/styled/TextArea';
import { InterviewStageEditProps } from './InterviewMetaInfoEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { grey } from '@mui/material/colors';
import { array, string } from 'yup';
import { useListErrors } from './useListErrors';

const QUESTION_SCHEMA = string()
  .min(3, 'Question must be at least 3 characters')
  .required('Required');
const QUESTIONS_LIST_SCHEMA = array(QUESTION_SCHEMA).min(1, 'Enter at least one question');

export function InterviewQuestionsEdit({
  interviewConfig,
  handleChange,
  handleBack,
  handleNext,
  submitting,
}: InterviewStageEditProps) {
  const { itemErrors, validateItem, validateList, listErrors } = useListErrors(
    QUESTION_SCHEMA,
    QUESTIONS_LIST_SCHEMA
  );

  const onQuestionChange = (index: number, value: string) => {
    const newQuestions = [...(interviewConfig?.questions || [])];
    newQuestions[index] = { question: value };
    handleChange({ ...interviewConfig, questions: newQuestions });
    validateItem(index, value);
  };

  const onQuestionRemove = (index: number) => {
    const newQuestions = [...(interviewConfig?.questions || [])];
    newQuestions.splice(index, 1);
    handleChange({ ...interviewConfig, questions: newQuestions });
    validateList(newQuestions.map(question => question.question || ''));
  };

  const onQuestionCreate = () => {
    const newQuestions = [...(interviewConfig?.questions || []), { question: '' }];
    handleChange({ ...interviewConfig, questions: newQuestions });
    validateList(newQuestions.map(question => question.question || ''));
  };

  const validate = () => {
    return validateList(interviewConfig?.questions?.map(question => question.question || '') || []);
  };

  const handleSubmit = () => {
    if (validate()) {
      handleNext();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 'calc(100vh - 260px)',
          overflowY: 'hidden',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{ height: '100%', overflowY: 'auto', padding: '20px', boxSizing: 'border-box' }}
        >
          <Typography level="title-md" gutterBottom sx={{ marginBottom: '10px' }}>
            Add questions you want to ask the candidate.
          </Typography>
          <Typography level="body-sm" gutterBottom sx={{ marginBottom: '20px' }}>
            The AI will use these questions to assess the candidate. Make sure that the questions
            are relevant to the criteria you added before.
          </Typography>
          <FormControl error={!!listErrors}>
            {interviewConfig?.questions?.map((question, index) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: '20px', width: 600 }}
              >
                <FormControl error={!!itemErrors[index]} sx={{ width: 400 }}>
                  <Typography level="title-sm" gutterBottom>
                    Question {index + 1}
                  </Typography>
                  <Textarea
                    value={question.question}
                    onChange={e => onQuestionChange(index, e.target.value)}
                    minRows={3}
                    size={'sm'}
                    sx={{ fontWeight: '500' }}
                  />
                  {itemErrors[index] && <FormHelperText>{itemErrors[index]}</FormHelperText>}
                </FormControl>
                <IconButton variant="plain" onClick={() => onQuestionRemove(index)}>
                  <DeleteOutlineIcon style={{ color: grey[600] }} />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onQuestionCreate()}
              sx={{ marginBottom: '20px' }}
            >
              Add question
            </Button>
            {listErrors && <FormHelperText>{listErrors}</FormHelperText>}
          </FormControl>
        </div>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button variant="plain" color="neutral" onClick={() => handleBack()}>
          Back
        </Button>
        <Button onClick={() => handleSubmit()} loading={submitting}>
          Submit
        </Button>
      </Box>
    </>
  );
}
