import { isWeb } from '@livekit/components-core';
import { RoomEvent, Track } from 'livekit-client';
import * as React from 'react';
import {
  ConnectionStateToast,
  RoomAudioRenderer,
  GridLayout,
  DisconnectButton,
  LeaveIcon,
} from '@livekit/components-react';
import { useTracks } from '@livekit/components-react';
import { ParticipantTile } from './ParticipantTile';
import { Box } from '@mui/joy';

export interface VideoConferenceProps extends React.HTMLAttributes<HTMLDivElement> {}

export function VideoConference({ ...props }: VideoConferenceProps) {
  const tracks = useTracks([{ source: Track.Source.Camera, withPlaceholder: true }], {
    updateOnlyOn: [RoomEvent.ActiveSpeakersChanged],
    onlySubscribed: false,
  });

  return (
    <div className="lk-video-conference" {...props}>
      {isWeb() && (
        <div className="lk-video-conference-inner">
          <div className="lk-grid-layout-wrapper">
            <GridLayout tracks={tracks}>
              <ParticipantTile />
            </GridLayout>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <DisconnectButton>
              <LeaveIcon />
              {'Leave'}
            </DisconnectButton>
          </Box>
        </div>
      )}
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </div>
  );
}
