import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/app/App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@livekit/components-styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import '@fontsource/inter';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import './components/inputTheme';
import { Login } from './pages/login/Login';
import { AdminApp } from './pages/admin/AdminApp';
import { Setting } from './pages/setting/setting';
import { App as InterviewApp } from './pages/interview/app/App';
import { InterviewSessionApp } from 'pages/interview-session/app/App';
import { INTERVIEW_PATH } from 'pages/interview/app/constants';
import { INTERVIEW_SESSION_PATH } from 'pages/interview-session/constants';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary fallback={<div>Oops!!! Something went wrong</div>}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/admin/*" element={<AdminApp />} />
        {/* <Route path="/*" element={<App />} /> */}
        <Route path="/*" element={<InterviewApp />} />
        <Route path="/setting" element={<Setting />} />
        <Route path={`${INTERVIEW_PATH}/*`} element={<InterviewApp />} />
        <Route path={`${INTERVIEW_SESSION_PATH}/*`} element={<InterviewSessionApp />} />
      </Routes>
    </ErrorBoundary>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
