import { create } from 'zustand';
import { AppStore } from './types';
import { getBootstrapInfo } from '../api/api';
import { createProjectSlice } from '../pages/project/slice/projectSlice';
import { createOutreachSlice } from '../pages/outreach/slice/outreachSlice';
import { createCandidateSlice } from '../pages/candidate/slice/candidateSlice';
import { createInterviewSlice } from 'pages/interview/slice/interviewSlice';
import { createInterviewSessionSlice } from 'pages/interview-session/interviewSessionSlice';

const initialState: Partial<AppStore> = {
  bootstrapInfo: undefined,
};

export const useAppStore = create<AppStore>((set, get) => ({
  ...initialState,
  ...createProjectSlice(set, get),
  ...createOutreachSlice(set, get),
  ...createCandidateSlice(set, get),
  ...createInterviewSlice(set, get),
  ...createInterviewSessionSlice(set, get),
  getBootstrapInfo: async () => {
    const resp = await getBootstrapInfo();
    set({
      bootstrapInfo: {
        projects: resp.projects || [],
      },
    });
  },
}));
