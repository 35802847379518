import { useCallback, useEffect, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteRenderOptionState,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@mui/joy';

export function AutoCompleteFetcher<T>({
  label,
  selectedItems,
  onSelectionChange,
  fetchItems,
  getOptionLabel,
  constructOptionFromInputValue,
  freeSolo = false,
  renderOption,
  multiple = true,
  error,
}: {
  label: string;
  error?: string;
  selectedItems: T[] | T;
  onSelectionChange: (items: T[] | T) => void;
  fetchItems: (searchQuery: string) => Promise<T[]>;
  getOptionLabel: (item: T) => string;
  renderOption?: (
    props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
    option: T
  ) => React.ReactNode;
  constructOptionFromInputValue?: (inputValue: string) => T;
  freeSolo?: boolean;
  multiple?: boolean;
}) {
  // ... existing state declarations ...
  const [items, setItems] = useState<T[]>([]);
  const [itemsLoading, setItemsLoading] = useState(false);

  const fetchOptions = async (searchQuery: string) => {
    if (!searchQuery || searchQuery.length < 2) {
      setItems([]);
      return;
    }

    try {
      setItemsLoading(true);
      const items = await fetchItems(searchQuery);
      setItems(items || []);
    } catch (error) {
      console.error('Error fetching items:', error);
      setItems([]);
    } finally {
      setItemsLoading(false);
    }
  };

  const debouncedFetchItems = useMemo(() => debounce(fetchOptions, 300), []);

  useEffect(() => {
    return () => {
      debouncedFetchItems.cancel();
    };
  }, [debouncedFetchItems]);

  const finalRenderOption =
    renderOption ||
    ((props, option) => (
      <AutocompleteOption {...props} sx={{ fontSize: '14px', fontWeight: '500' }}>
        {getOptionLabel(option as T)}
      </AutocompleteOption>
    ));

  return (
    <FormControl error={!!error}>
      <FormLabel>{label}</FormLabel>
      <Autocomplete<T, boolean, true, typeof freeSolo | undefined>
        multiple={multiple}
        options={items}
        value={selectedItems as T[]}
        onChange={(e, newValue) => {
          onSelectionChange(newValue as T[]);
        }}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          if (inputValue !== '' && constructOptionFromInputValue) {
            options.push(constructOptionFromInputValue(inputValue));
          }

          return options;
        }}
        slotProps={{
          listbox: {
            sx: theme => ({
              zIndex: theme.vars.zIndex.modal,
            }),
          },
        }}
        sx={{
          '&::before': {
            display: 'none',
          },
          backgroundColor: 'rgb(255, 255, 255)',
          borderColor: 'rgb(228, 228, 231)',
          '&:focus-within': {
            borderColor: 'rgb(161, 161, 170)',
            boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px,
            rgb(161, 161, 170) 0px 0px 0px 1px,
            rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;`,
          },
        }}
        onInputChange={(e, value) => debouncedFetchItems(value)}
        loading={itemsLoading}
        getOptionLabel={option => getOptionLabel(option as T)}
        freeSolo={freeSolo}
        renderOption={finalRenderOption}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
