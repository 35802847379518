import { Box, Button, FormControl, FormHelperText, Typography } from '@mui/joy';
import { InterviewConfig } from 'api-types';
import { Input } from 'components/styled/Input';
import { Textarea } from 'components/styled/TextArea';
import { useState } from 'react';
import { object, string } from 'yup';
import { AutoCompleteFetcher } from 'components/autocomplete/AutoCompleteFetcher';
import { fetchJobTitles } from 'api/api';

const nameSchema = string()
  .required('Name is required')
  .min(3, 'Name must be at least 3 characters')
  .max(50, 'Name must be at most 50 characters');

const descriptionSchema = string()
  .required('Description is required')
  .min(3, 'Description must be at least 3 characters')
  .max(200, 'Description must be at most 200 characters');

export const roleSchema = string().required('Role is required');

export const focusSchema = string().required('Focus is required');

export const metaInfoSchema = object({
  name: nameSchema,
  description: descriptionSchema,
});

export interface InterviewStageEditProps {
  interviewConfig: InterviewConfig | undefined;
  submitting: boolean;
  handleChange: (interviewConfig: InterviewConfig) => void;
  handleBack: () => void;
  handleNext: () => void;
}

export function InterviewMetaInfoEdit({
  interviewConfig,
  handleChange,
  handleBack,
  handleNext,
  submitting,
}: InterviewStageEditProps) {
  const [nameError, setNameError] = useState<string | undefined>();
  const [descriptionError, setDescriptionError] = useState<string | undefined>();
  const [roleError, setRoleError] = useState<string | undefined>();
  const [focusError, setFocusError] = useState<string | undefined>();

  const onNameChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      metaInfo: { ...interviewConfig?.metaInfo, name: value },
    });
    validateName(value);
  };

  const onDescriptionChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      metaInfo: { ...interviewConfig?.metaInfo, description: value },
    });
    validateDescription(value);
  };

  const validateName = (name: string | undefined) => {
    try {
      nameSchema.validateSync(name);
      setNameError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setNameError(error.message);
      }
      return false;
    }
  };

  const validateDescription = (description: string | undefined) => {
    try {
      descriptionSchema.validateSync(description);
      setDescriptionError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setDescriptionError(error.message);
      }
      return false;
    }
  };

  const validateRole = (role: string | undefined) => {
    try {
      roleSchema.validateSync(role);
      setRoleError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setRoleError(error.message);
      }
      return false;
    }
  };

  const onRoleChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      role: value,
    });
    validateRole(value);
  };

  const validateFocus = (focus: string | undefined) => {
    try {
      focusSchema.validateSync(focus);
      setFocusError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setFocusError(error.message);
      }
      return false;
    }
  };

  const onFocusChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      focus: value,
    });
    validateFocus(value);
  };

  const validate = () => {
    const nameValid = validateName(interviewConfig?.metaInfo?.name);
    const descriptionValid = validateDescription(interviewConfig?.metaInfo?.description);
    const roleValid = validateRole(interviewConfig?.role);
    const focusValid = validateFocus(interviewConfig?.focus);
    return nameValid && descriptionValid && roleValid && focusValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      handleNext();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 260px)',
          flexGrow: 1,
        }}
      >
        <FormControl
          sx={{ marginBottom: '20px', maxWidth: '500px', width: '100%' }}
          error={!!nameError}
        >
          <Typography level="title-sm" gutterBottom>
            Name
          </Typography>
          <Input
            name="metaInfo.name"
            id="metaInfo.name"
            value={interviewConfig?.metaInfo?.name}
            onChange={e => onNameChange(e.target.value)}
            placeholder="Behavioral Interview"
            sx={{ fontWeight: '500' }}
            size="sm"
          />
          <FormHelperText>{nameError}</FormHelperText>
        </FormControl>
        <FormControl
          sx={{ marginBottom: '20px', maxWidth: '500px', width: '100%' }}
          error={!!descriptionError}
        >
          <Typography level="title-sm" gutterBottom>
            Description
          </Typography>
          <Textarea
            name="metaInfo.description"
            id="metaInfo.description"
            value={interviewConfig?.metaInfo?.description}
            onChange={e => onDescriptionChange(e.target.value)}
            size="sm"
            minRows={3}
            sx={{ fontWeight: '500' }}
            placeholder="Behavioral Interview to understand the candidate's fit for the role"
          />
          <FormHelperText>{descriptionError}</FormHelperText>
        </FormControl>
        <Box sx={{ marginBottom: '20px', maxWidth: '500px', width: '100%' }}>
          <AutoCompleteFetcher<string>
            label="Role"
            selectedItems={interviewConfig?.role || ''}
            onSelectionChange={value => onRoleChange(value as string)}
            fetchItems={async (searchQuery: string) => {
              const { values } = await fetchJobTitles({ prefix: searchQuery });
              return values?.map(value => value.value || '') || [];
            }}
            getOptionLabel={option => option || ''}
            freeSolo
            multiple={false}
            constructOptionFromInputValue={inputValue => inputValue}
            error={roleError}
          />
        </Box>
        <FormControl
          sx={{ marginBottom: '20px', maxWidth: '500px', width: '100%' }}
          error={!!focusError}
        >
          <Typography level="title-sm" gutterBottom>
            Focus
          </Typography>
          <Textarea
            name="focus"
            id="focus"
            value={interviewConfig?.focus}
            onChange={e => onFocusChange(e.target.value)}
            size="sm"
            minRows={3}
            sx={{ fontWeight: '500' }}
          />
          <FormHelperText>{focusError}</FormHelperText>
          <div>
            <Typography level="body-sm" gutterBottom>
              This will used to by our AI agent to conduct the inteview with a particular focus.
              Examples:
            </Typography>
            <Typography level="body-sm" gutterBottom>
              - Behavioral interview to understand the candidate's fit for the role.
            </Typography>
            <Typography level="body-sm" gutterBottom>
              - Technical interview to understand the candidate's technical skills
            </Typography>
            <Typography level="body-sm" gutterBottom>
              - Culture Fit interview to understand the candidate's fit for the company
            </Typography>
            <Typography level="body-sm" gutterBottom>
              - Strategy interview to understand the candidate's strategic thinking
            </Typography>
            <Typography level="body-sm" gutterBottom>
              - Sales negotition interview to understand the candidate's sales skills
            </Typography>
          </div>
        </FormControl>
      </Box>
      <Button onClick={handleSubmit} sx={{ ml: 'auto' }} loading={submitting}>
        Next
      </Button>
    </>
  );
}
