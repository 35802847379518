import { Toolbar } from '@mui/material';

import { Box, ListItemButton, ListItemContent, Typography } from '@mui/joy';
import { AppBar } from '@mui/material';
import logo from '../../../assets/logo.png';
import styles from './AppNavigation.module.css';
import { TOP_APP_BAR_HEIGHT } from '../../../constants';
import { Link } from 'react-router-dom';

interface TopAppBarProps {
  backgroundColor?: string;
}

export function TopAppBar(props: TopAppBarProps) {
  const { backgroundColor = '#272525' } = props;
  return (
    <Box sx={{ flexGrow: 0 }}>
      <AppBar
        position="static"
        sx={{
          flexGrow: 0,
          height: TOP_APP_BAR_HEIGHT,
          backgroundColor: backgroundColor,
          color: '#272525',
        }}
      >
        <Toolbar>
          <ListItemButton
            className={styles.listButton}
            to={'/'}
            component={Link}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            <ListItemContent>
              <Typography level="h3" sx={{ color: '#fff' }} className={styles.heading}>
                <img
                  src={logo}
                  alt="logo"
                  className={styles.logo}
                  style={{ backgroundColor: backgroundColor }}
                />
                Arka
              </Typography>
            </ListItemContent>
          </ListItemButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
