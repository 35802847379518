import React, { useState, useMemo, useCallback } from 'react';
import { LocalUserChoices } from '@livekit/components-react';
import { PageClientProps } from '../types/types';
import { VideoConferenceComponent } from '../components/VideoConferenceComponent';
import { useAppStore } from 'store/appStore';
import { PreJoin } from './PreJoin';

export function RoomComponent(props: PageClientProps) {
  const { roomName, hq, codec, interviewId } = props;
  const getConnectionDetails = useAppStore(state => state.getConnectionDetails);

  const [preJoinChoices, setPreJoinChoices] = useState<LocalUserChoices | undefined>(undefined);
  const [connectionDetails, setConnectionDetails] = useState<
    | {
        participantToken: string;
        serverUrl: string;
      }
    | undefined
  >(undefined);

  const preJoinDefaults = useMemo(() => {
    return {
      username: '',
      videoEnabled: true,
      audioEnabled: true,
    };
  }, []);

  const handlePreJoinSubmit = useCallback(
    async (values: LocalUserChoices) => {
      setPreJoinChoices(values);

      try {
        const connectionDetails = await getConnectionDetails({
          roomName: roomName,
          participantName: values.username,
          metadata: {
            interviewId: interviewId,
          },
        });

        setConnectionDetails(connectionDetails);
      } catch (error) {
        console.error('Error fetching connection details:', error);
      }
    },
    [roomName, getConnectionDetails]
  );

  const handlePreJoinValidate = useCallback((values: LocalUserChoices) => {
    return values.username !== '' && values.audioEnabled && values.videoEnabled;
  }, []);

  const handlePreJoinError = useCallback((e: any) => console.error(e), []);

  return (
    <main style={{ height: '100%' }} data-lk-theme="default">
      {connectionDetails === undefined || preJoinChoices === undefined ? (
        <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
          <PreJoin
            defaults={preJoinDefaults}
            onSubmit={handlePreJoinSubmit}
            onError={handlePreJoinError}
            onValidate={handlePreJoinValidate}
          />
        </div>
      ) : (
        <VideoConferenceComponent
          connectionDetails={connectionDetails}
          userChoices={preJoinChoices}
          options={{ codec, hq }}
        />
      )}
    </main>
  );
}
