import { Input as MuiInput, InputProps, styled } from '@mui/joy';

export const Input = styled(MuiInput)`
  &::before {
    display: none;
  }

  background-color: rgb(255, 255, 255);
  border-color: rgb(228, 228, 231);

  &:focus-within {
    border-color: rgb(161, 161, 170);

    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 0px,
      rgb(161, 161, 170) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
`;
