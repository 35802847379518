// Capitalize the first letter of each word in a sentence
export function capitalizeSentence(sentence: string) {
  const words = sentence.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
    }
  }
  return words.join(' ');
}
