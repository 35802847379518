import { BarVisualizer, useVoiceAssistant } from '@livekit/components-react';

export const VoiceAssistantTile = () => {
  const { state, audioTrack } = useVoiceAssistant();

  if (!audioTrack) {
    return null;
  }

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <BarVisualizer
        state={state}
        barCount={8}
        trackRef={audioTrack}
        options={{
          minHeight: 5,
        }}
      />
    </div>
  );
};
