import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import { Snackbar } from '../../../components/Snackbar/SnackbarContext';
import { TopAppBar } from '../../app/app-nav/TopAppBar';
import LiveInterviewHome from '../live-interview/LiveInterviewHome';
import InterviewSessionRoom from '../live-interview/InterviewSessionRoom';
import { ROOM_PATH } from '../constants';
import { useIsLiveInterviewRoomRoute } from '../hooks/useIsLiveInterviewRoomRoute';

export function InterviewSessionApp() {
  const isLiveInterviewRoomRoute = useIsLiveInterviewRoomRoute();
  return (
    <div
      className={styles.app}
      style={isLiveInterviewRoomRoute ? { background: 'var(--lk-bg)' } : {}}
      data-lk-theme="default"
    >
      <Snackbar>
        <TopAppBar backgroundColor={isLiveInterviewRoomRoute ? 'var(--lk-bg)' : undefined} />
        <div className={styles.appContainer}>
          <Routes>
            <Route path={`/:interviewId`} element={<LiveInterviewHome />} />
            <Route
              path={`/:interviewId/${ROOM_PATH}/:roomName`}
              element={<InterviewSessionRoom />}
            />
          </Routes>
        </div>
      </Snackbar>
    </div>
  );
}
