import { StoreApi } from 'zustand';
import { ConnectionDetailsRequest, ConnectionDetailsResponse } from 'api-types';
import { getConnectionDetails } from 'api/api';
export interface InterviewSessionSlice {
  activeInterviewId?: string;
  getConnectionDetails: (req: ConnectionDetailsRequest) => Promise<ConnectionDetailsResponse>;
}
export const createInterviewSessionSlice = (
  set: StoreApi<InterviewSessionSlice>['setState'],
  get: StoreApi<InterviewSessionSlice>['getState']
): InterviewSessionSlice => ({
  getConnectionDetails: async (req: ConnectionDetailsRequest) => {
    const connectionDetails = await getConnectionDetails(req);
    return connectionDetails;
  },
});
