import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import { useAppStore } from '../../../store/appStore';
import { Snackbar } from '../../../components/Snackbar/SnackbarContext';
import { TopAppBar } from '../../app/app-nav/TopAppBar';
import { InteviewHomeScreen } from '../interview-home/InteviewHomeScreen';
import { InterviewEditor } from '../edit-interview/InterviewEditor';
import { CREATE_PATH, VIEW_PATH } from './constants';
import { EDIT_PATH } from './constants';
import { InterviewLibraryConfigView } from '../interview-library/InterviewLibraryConfigView';

export function App() {
  const getBootstrapInfo = useAppStore(state => state.getBootstrapInfo);
  const bootstrapInfo = useAppStore(state => state.bootstrapInfo);

  useEffect(() => {
    if (!bootstrapInfo) {
      getBootstrapInfo();
    }
  }, []);

  return (
    <div className={styles.app}>
      <Snackbar>
        <TopAppBar />
        <div className={styles.appContainer}>
          <Routes>
            <Route path="/" element={<InteviewHomeScreen />} />
            <Route path={CREATE_PATH} element={<InterviewEditor />} />
            <Route path={`${EDIT_PATH}/:interviewId`} element={<InterviewEditor />} />
            <Route path={`${VIEW_PATH}/:interviewId`} element={<InterviewLibraryConfigView />} />
          </Routes>
        </div>
      </Snackbar>
    </div>
  );
}
