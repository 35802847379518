import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { RoomComponent } from './room-setup/RoomComponent';
import { isVideoCodec } from './types/types';

const InterviewSessionRoom: React.FC = () => {
  const { roomName, interviewId } = useParams<{ roomName: string; interviewId: string }>();
  const [searchParams] = useSearchParams();

  const codecParam = searchParams.get('codec');
  const codec = codecParam && isVideoCodec(codecParam) ? codecParam : 'vp9';

  const hq = searchParams.get('hq') === 'true';

  return <RoomComponent roomName={roomName!} hq={hq} codec={codec} interviewId={interviewId!} />;
};

export default InterviewSessionRoom;
