import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { VideoConferenceProps } from '../types/types';
import { LiveKitRoom } from '@livekit/components-react';
import { RoomOptions, VideoPresets, Room, RoomConnectOptions } from 'livekit-client';
import { useNavigate, useParams } from 'react-router-dom';
import { INTERVIEW_SESSION_PATH } from 'pages/interview-session/constants';
import { VideoConference } from './VideoConference';

export function VideoConferenceComponent({
  connectionDetails,
  userChoices,
  options,
}: VideoConferenceProps) {
  const { codec, hq } = options;
  const navigate = useNavigate();
  const { interviewId } = useParams();

  const roomOptions = useMemo((): RoomOptions => {
    return {
      videoCaptureDefaults: {
        deviceId: userChoices.videoDeviceId ?? undefined,
        resolution: hq ? VideoPresets.h2160 : VideoPresets.h720,
      },
      publishDefaults: {
        dtx: false,
        videoSimulcastLayers: hq
          ? [VideoPresets.h1080, VideoPresets.h720]
          : [VideoPresets.h540, VideoPresets.h216],
        red: true,
        videoCodec: codec,
      },
      audioCaptureDefaults: {
        deviceId: userChoices.audioDeviceId ?? undefined,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
    };
  }, [userChoices, hq, codec]);

  const room = useMemo(() => new Room(roomOptions), [roomOptions]);

  const connectOptions = useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);

  const handleOnLeave = useCallback(() => {
    navigate(`${INTERVIEW_SESSION_PATH}/${interviewId}`);
  }, []);

  const handleError = React.useCallback((error: Error) => {
    alert(`Encountered an unexpected error, check the console logs for details: ${error.message}`);
  }, []);

  return (
    <>
      <LiveKitRoom
        connect={true}
        room={room}
        token={connectionDetails.participantToken}
        serverUrl={connectionDetails.serverUrl}
        connectOptions={connectOptions}
        video={userChoices.videoEnabled}
        audio={userChoices.audioEnabled}
        onDisconnected={handleOnLeave}
        onError={handleError}
      >
        <VideoConference />
      </LiveKitRoom>
    </>
  );
}
