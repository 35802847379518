import { Box, Button, Divider, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerLoader } from 'components/Loader/SpinnerLoader';
import { useAppStore } from 'store/appStore';
import { CREATE_PATH, INTERVIEW_PATH } from '../app/constants';

export function InterviewLibraryConfigView() {
  const { interviewId } = useParams();
  const interviewLibrary = useAppStore(state => state.interviewLibraryConfigs);
  const interviewConfig = interviewLibrary?.find(interview => interview.id === interviewId);
  const fetchInterviewLibraryConfigs = useAppStore(state => state.fetchInterviewLibraryData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUseInterviewTemplate = () => {
    navigate(`${INTERVIEW_PATH}${CREATE_PATH}`, {
      state: {
        defaultInterviewConfig: interviewConfig,
      },
    });
  };

  useEffect(() => {
    if (!interviewLibrary) {
      setLoading(true);
      fetchInterviewLibraryConfigs().finally(() => {
        setLoading(false);
      });
    }
  }, [interviewLibrary]);

  return (
    <>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <Box
          sx={{
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              height: 'calc(100vh - 260px)',
            }}
          >
            <Box sx={{ marginBottom: '20px' }} display={'flex'} justifyContent={'center'}>
              <Typography level="title-md" gutterBottom>
                {interviewConfig?.metaInfo?.name}
              </Typography>
            </Box>
            <Box>
              <Typography level="title-md" gutterBottom>
                Description
              </Typography>
              <Typography level="body-md" gutterBottom>
                {interviewConfig?.metaInfo?.description}
              </Typography>
            </Box>

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

            <Box>
              <Typography level="title-md" gutterBottom>
                Screening Criteria
              </Typography>
              {interviewConfig?.screeningCriteria?.criteria?.map(criteria => (
                <Typography
                  level="body-md"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <span style={{ marginRight: '8px' }}>•</span> {criteria}
                </Typography>
              ))}
            </Box>

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

            <Box>
              <Typography level="title-md" gutterBottom>
                Questions
              </Typography>
              {interviewConfig?.questions?.map(question => (
                <Typography
                  level="body-md"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <span style={{ marginRight: '8px' }}>•</span> {question.question}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'end'}
            sx={{ width: '100%' }}
          >
            <Button onClick={handleUseInterviewTemplate}>Use Interview Template</Button>
          </Box>
        </Box>
      )}
    </>
  );
}
