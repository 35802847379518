import { useState } from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
}

export function Image({ src, alt, fallback = '', ...rest }: ImageProps) {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return error && fallback ? (
    <img src={fallback} alt={alt} width={16} height={16} {...rest} />
  ) : (
    <img src={src} alt={alt} width={16} height={16} onError={handleError} {...rest} />
  );
}
