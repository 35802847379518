import { LocalAudioTrack, LocalVideoTrack, videoCodecs } from 'livekit-client';
import { VideoCodec } from 'livekit-client';
import { LocalUserChoices } from '@livekit/components-react';

export interface SessionProps {
  roomName: string;
  identity: string;
  audioTrack?: LocalAudioTrack;
  videoTrack?: LocalVideoTrack;
  region?: string;
  turnServer?: RTCIceServer;
  forceRelay?: boolean;
}

export interface TokenResult {
  identity: string;
  accessToken: string;
}

export function isVideoCodec(codec: string): codec is VideoCodec {
  return videoCodecs.includes(codec as VideoCodec);
}

export type ConnectionDetails = {
  serverUrl: string;
  roomName: string;
  participantName: string;
  participantToken: string;
};

export type PageClientProps = {
  roomName: string;
  region?: string;
  hq: boolean;
  codec: VideoCodec;
  interviewId: string;
};

export type VideoConferenceProps = {
  userChoices: LocalUserChoices;
  connectionDetails: {
    participantToken: string;
    serverUrl: string;
  };
  options: {
    hq: boolean;
    codec: VideoCodec;
  };
};
