import { StoreApi } from 'zustand';
import { AppStore } from '../../../store/types';
import { InterviewSlice } from './types';
import {
  deleteInterviewConfig,
  fetchAllInterviews,
  fetchInterviewConfig,
  createInterviewConfig,
  updateInterviewConfig,
  fetchInterviewLibraryData,
} from 'api/api';
import { ConnectionDetailsRequest, InterviewConfig } from 'api-types';

const initialState: Partial<InterviewSlice> = {
  interviewConfig: undefined,
  interviewConfigs: undefined,
};

export const createInterviewSlice = (
  set: StoreApi<InterviewSlice>['setState'],
  get: StoreApi<InterviewSlice>['getState']
): InterviewSlice => ({
  ...initialState,
  activeInterviewId: undefined,
  setActiveInterviewId: (id: string) => {
    set({ activeInterviewId: id, interviewConfig: undefined });
  },
  resetInterviewConfig: () => {
    set({ interviewConfig: undefined, activeInterviewId: undefined });
  },
  fetchAllInterviews: async () => {
    const { interviewConfigs } = await fetchAllInterviews({});
    set({ interviewConfigs });
    return interviewConfigs || [];
  },
  fetchInterviewConfig: async () => {
    const { activeInterviewId } = get();
    if (!activeInterviewId) {
      return;
    }
    const { interviewConfig } = await fetchInterviewConfig({ interviewId: activeInterviewId });
    if (get().activeInterviewId === interviewConfig?.id) {
      set({ interviewConfig });
    }
    return interviewConfig;
  },
  fetchInterviewLibraryData: async () => {
    const { interviewLibraryData } = await fetchInterviewLibraryData({});
    set({ interviewLibraryConfigs: interviewLibraryData });
    return interviewLibraryData || [];
  },
  updateInterviewConfig: (interviewConfig: InterviewConfig) => {
    set({ interviewConfig });
  },
  upsertInterviewConfig: async (interviewConfig: InterviewConfig) => {
    const { activeInterviewId } = get();
    if (!activeInterviewId) {
      const { interviewConfig: createdInterviewConfig } = await createInterviewConfig({
        interviewConfig,
      });
      set({ interviewConfig: createdInterviewConfig });
      return createdInterviewConfig;
    }
    const { interviewConfig: updatedInterviewConfig } = await updateInterviewConfig({
      interviewId: activeInterviewId,
      interviewConfig,
    });
    if (get().activeInterviewId === updatedInterviewConfig?.id) {
      set({ interviewConfig: updatedInterviewConfig });
    }
    return updatedInterviewConfig;
  },
  deleteInterviewConfig: async (id: string) => {
    await deleteInterviewConfig({
      interviewId: id,
    });
    const interviewConfigs = get().interviewConfigs?.filter(interview => interview.id !== id);
    set({ interviewConfigs });
  },
});
