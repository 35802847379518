import { CandidateItem } from 'api-types';
import { Divider, Typography } from '@mui/joy';
import styles from '../../candidate.module.css';
import { Section } from './Section';
import { humanizeDateRange, humanizeDegree } from '../../utils';
import { Image } from '../../../../components/Image/Image';
import emptyCompanyLogo from '../../../../assets/empty_company_logo.jpeg';

export function CandidateProfileDetails({ candidate }: { candidate: CandidateItem }) {
  return (
    <>
      {candidate.summary && (
        <div className={styles.section} style={{ marginTop: '0px' }}>
          <Typography level="title-md" fontWeight={'lg'} gutterBottom>
            About
          </Typography>
          <Typography level="body-sm" gutterBottom textColor="text.primary">
            {candidate.summary}
          </Typography>
        </div>
      )}

      {candidate.experiences?.length && (
        <>
          <Divider />
          <Section
            title={`Experience`}
            items={candidate.experiences.map(ex => ({
              title: ex.position || '',
              subtitle: ex.company,
              body: ex.startDate ? humanizeDateRange(ex.startDate, ex.endDate) : '',
              description: ex.description,
              image: (
                <Image
                  width={30}
                  height={'30px !important'}
                  src={ex.logoUrl || emptyCompanyLogo}
                  alt={''}
                  fallback={emptyCompanyLogo}
                />
              ),
            }))}
          />
        </>
      )}
      {candidate.education?.length && (
        <>
          <Divider />
          <Section
            title={`Education`}
            items={candidate.education.map(ed => ({
              title: ed.degree ? humanizeDegree(ed.degree, ed.fieldOfStudy) : '',
              subtitle: ed.institute,
              body: ed.startDate ? humanizeDateRange(ed.startDate, ed.endDate) : '',
              image: (
                <Image
                  src={ed.logoUrl || emptyCompanyLogo}
                  width={30}
                  height={'30px !important'}
                  alt={''}
                  fallback={emptyCompanyLogo}
                />
              ),
            }))}
          />
        </>
      )}
      {candidate.skills && (
        <>
          <Divider />
          <Section
            title={`Skills`}
            items={[
              {
                body: candidate.skills,
              },
            ]}
          />
        </>
      )}
    </>
  );
}
